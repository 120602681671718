import { Tag } from "@/store/tags/tags-slice";
import { LabelType } from "@faro-lotv/ielement-types";

/** Accessibility of a given tag */
export enum TagAccessibility {
  /** The user can see the tag, but cannot delete or modify it*/
  Read = "Read",
  /** The user can see, edit and delete the tag */
  Write = "Write",
  /** The user cannot see this tag */
  Hidden = "Hidden",
}

/** The mapping between a tag type and its accessibility */
export const TagAuthorization: Record<LabelType, TagAccessibility> = {
  [LabelType.SpotWalkMarker]: TagAccessibility.Hidden,
  [LabelType.LocationMarker]: TagAccessibility.Hidden,
  [LabelType.Uncategorized]: TagAccessibility.Hidden,
  [LabelType.Navisworks]: TagAccessibility.Read,
  [LabelType.Revit]: TagAccessibility.Read,
  [LabelType.ContinuousCapture]: TagAccessibility.Read,
  [LabelType.Videomode]: TagAccessibility.Read,
  [LabelType.Category]: TagAccessibility.Hidden,
  [LabelType.PrivacyBlurring]: TagAccessibility.Hidden,
  [LabelType.SitescapeScan]: TagAccessibility.Hidden,
  [LabelType.MergedPointCloud]: TagAccessibility.Hidden,
  [LabelType.ObjectDetection]: TagAccessibility.Read,
  [LabelType.Initializing]: TagAccessibility.Hidden,
};

/**
 * @returns True if the user can delete or modify the tag
 * @param tag The tag to check
 */
export function isTagWritable(tag: Tag): boolean {
  if (!tag.labelType) return true;
  return TagAuthorization[tag.labelType] === TagAccessibility.Write;
}

/**
 * @returns True if the user can see the tag
 * @param tag The tag to check
 */
export function isTagReadable(tag: Tag): boolean {
  if (!tag.labelType) return true;
  return TagAuthorization[tag.labelType] === TagAccessibility.Write;
}
