import {
  WaypointLabelRender,
  WaypointPosition,
} from "@/components/r3f/renderers/waypoint-label-render";
import { useWalkPlaceholderPositions } from "@/hooks/use-walk-placeholder-positions";
import {
  cameraVecToScreenVec,
  eventToScreenVec,
  worldVecToScreenVec,
} from "@/registration-tools/common/interaction/single-pin-interaction";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectActiveElement } from "@/store/selections-selectors";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  selectObjectVisibility,
  selectShouldShowWaypointsOnFloors,
  selectVisibilityDistance,
} from "@/store/view-options/view-options-selectors";
import { ViewObjectTypes } from "@/store/view-options/view-options-slice";
import { offsetPlaceholders } from "@/utils/offset-placeholders";
import {
  DEFAULT_CIRCLE_SIZE,
  LocationPlaceholderDefault,
  LocationPlaceholderHover,
  PanoramaPlaceholder,
  parseVector3,
  UPDATE_CAMERA_MONITOR_PRIORITY,
  useOverrideCursor,
  useSvg,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import {
  IElementGenericImgSheet,
  IElementImg360,
} from "@faro-lotv/ielement-types";
import { CameraMonitor } from "@faro-lotv/lotv";
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box3, Camera, MOUSE, Plane, Texture, Vector2, Vector3 } from "three";
import {
  useVisiblePlaceholders,
  useWaypoints,
} from "../../hooks/use-placeholders";
import { selectBestModelCameraFor360 } from "./animations/pano-to-model";

/** Minimum distance to consider a placeholder click */
const MIN_PLACEHOLDER_CLICK_DISTANCE = 0.1;

/** Number of pixel the mouse need to move between pointer down and up to consider it as a drag */
const POINTER_DRAG_THRESHOLD = 3;

/**
 * The type to be used for quick waypoint navigation.
 * To improve performance, the array of the objects will be re-allocated and calculated each time when camera changes.
 */
type WaypointOnScreen = {
  /** The waypoint to show */
  pano: IElementImg360;

  /** The location in world space where waypoint will be rendered */
  renderPosition: Vector3;

  /** The location on the screen */
  screenPos: Vector2;

  /** On screen placeholder radius squared when it facing camera (not used when shown on floors) */
  waypointScreenSize: number;

  /** The squared distance to camera */
  distanceToCamera: number;
};

export type WalkPlaceholdersProps = {
  /** All the placeholders for this pano */
  placeholders: IElementImg360[];

  /** The sheet to use to place the placeholders */
  sheetForElevation?: IElementGenericImgSheet;

  /** Optional clipping planes */
  clippingPlanes?: Plane[];

  /** Callback to signal a placeholder have been clicked */
  onPlaceholderClick?(element: IElementImg360, position: Vector3): void;

  /**
   * True to render the placeholders
   *
   * @default true
   */
  visible?: boolean;

  /**
   * True to enable the distance fade off of the placeholders
   *
   * @default false
   */
  shouldFadeOff?: boolean;
};

/**
 * @returns A component to render all the placeholders to navigate in panorama mode
 */
export function WalkPlaceholders({
  placeholders,
  sheetForElevation,
  onPlaceholderClick,
  clippingPlanes,
  shouldFadeOff = false,
  visible = true,
}: WalkPlaceholdersProps): JSX.Element | null {
  const activeTool = useAppSelector(selectActiveTool);
  const hasQuickNavigationFeature = useAppSelector(
    selectHasFeature(Features.WaypointNavigation),
  );

  const [waypointOnScreen, setWaypointOnScreen] = useState<WaypointOnScreen[]>(
    [],
  );

  // Create a camera monitor to know when the camera is still
  const cameraMonitor = useMemo(() => new CameraMonitor(), []);
  useFrame((_, delta) => {
    cameraMonitor.checkCameraMovement(camera, delta);
  }, UPDATE_CAMERA_MONITOR_PRIORITY);

  const [isHovered, setIsHovered] = useState(false);

  const defaultTexture = useSvg(LocationPlaceholderDefault, 512, 512);
  const hoverTexture = useSvg(LocationPlaceholderHover, 512, 512);

  // Placeholders will be shown at the scan position and they will face the camera if this option is disabled
  // Otherwise they are placed on the floor, looking up
  const shouldShowWaypointsOnFloor = useAppSelector(
    selectShouldShowWaypointsOnFloors,
  );

  const activeElement = useAppSelector(selectActiveElement);

  const placeholdersWithoutCurrentPano = useMemo(
    () =>
      placeholders.filter(
        (placeholder) => placeholder.id !== activeElement?.id,
      ),
    [activeElement?.id, placeholders],
  );

  // Use the scan positions if the option to show the waypoints on the floor is disabled
  const positions = useWalkPlaceholderPositions(
    placeholdersWithoutCurrentPano,
    sheetForElevation,
    !shouldShowWaypointsOnFloor,
  );

  const { visiblePlaceholders, visiblePositions } = useVisiblePlaceholders({
    placeholders: placeholdersWithoutCurrentPano,
    positions,
    clippingPlanes,
  });

  const { placeholdersOffset, shiftedPlaceholders } = useMemo(
    () => offsetPlaceholders(visiblePositions),
    [visiblePositions],
  );

  const waypoints = useWaypoints(visiblePlaceholders, visiblePositions);

  const store = useAppStore();
  const onWaypointClicked = useCallback(
    (pano: IElementImg360) => {
      if (onPlaceholderClick) {
        const position = selectBestModelCameraFor360(
          pano,
          sheetForElevation,
        )(store.getState());
        onPlaceholderClick(pano, parseVector3(position));
      }
    },
    [onPlaceholderClick, sheetForElevation, store],
  );

  const domElement = useThreeEventTarget();
  useOverrideCursor("pointer", isHovered, domElement);

  const { camera } = useThree();

  const [closestWaypoint, setClosestWaypoint] = useState<
    IElementImg360 | undefined
  >();
  const clickPos = useRef<Vector2>();

  const onPointerDown = useCallback(
    (event: PointerEvent) => {
      clickPos.current = new Vector2(event.clientX, event.clientY);
      if (event.button === MOUSE.LEFT && !closestWaypoint) {
        setClosestWaypoint(
          findClosestWaypoint(
            waypointOnScreen,
            domElement,
            event,
            shouldShowWaypointsOnFloor,
          ),
        );
      }
    },
    [closestWaypoint, domElement, shouldShowWaypointsOnFloor, waypointOnScreen],
  );

  const onPointerMove = useCallback(
    (ev: PointerEvent) => {
      // when dragging, do not look for closest waypoint
      if (clickPos.current) return;

      // find the closest waypoint and update state if it is different from previous one
      const newClosestWaypoint = findClosestWaypoint(
        waypointOnScreen,
        domElement,
        ev,
        shouldShowWaypointsOnFloor,
      );
      if (newClosestWaypoint !== closestWaypoint) {
        setClosestWaypoint(newClosestWaypoint);
      }
    },
    [closestWaypoint, domElement, shouldShowWaypointsOnFloor, waypointOnScreen],
  );

  const onPointerUp = useCallback(
    (event: PointerEvent) => {
      if (
        event.button !== MOUSE.LEFT ||
        !closestWaypoint ||
        !clickPos.current ||
        clickPos.current.distanceTo(new Vector2(event.clientX, event.clientY)) >
          POINTER_DRAG_THRESHOLD
      ) {
        clickPos.current = undefined;
        return;
      }
      event.stopPropagation();
      clickPos.current = undefined;
      onWaypointClicked(closestWaypoint);
    },
    [closestWaypoint, onWaypointClicked],
  );

  useEffect(() => {
    if (activeTool || !hasQuickNavigationFeature) return;

    domElement.addEventListener("pointermove", onPointerMove);
    domElement.addEventListener("pointerdown", onPointerDown);
    domElement.addEventListener("pointerup", onPointerUp);

    return () => {
      domElement.removeEventListener("pointermove", onPointerMove);
      domElement.removeEventListener("pointerup", onPointerUp);
      domElement.removeEventListener("pointerdown", onPointerDown);
    };
  }, [
    activeTool,
    closestWaypoint,
    domElement,
    hasQuickNavigationFeature,
    onPointerDown,
    onPointerMove,
    onPointerUp,
    onWaypointClicked,
  ]);

  useEffect(() => {
    const cameraStopped = cameraMonitor.cameraStoppedMoving.on(() => {
      setWaypointOnScreen(findWaypointsOnScreen(waypoints, camera, domElement));
    });
    return () => {
      cameraStopped.dispose();
    };
  }, [camera, cameraMonitor.cameraStoppedMoving, domElement, waypoints]);

  const shouldWayPointsBeVisible = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypoints),
  );

  const shouldDisplayWaypointLabels = useAppSelector(
    selectObjectVisibility(ViewObjectTypes.waypointLabels),
  );
  if (!visible || !shouldWayPointsBeVisible) {
    return null;
  }

  return (
    <>
      <group
        position={placeholdersOffset}
        name="placeholders"
        onPointerEnter={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)}
      >
        {visiblePlaceholders.map((el, index) => (
          <WalkPlaceholder
            key={el.id}
            element={el}
            position={shiftedPlaceholders[index]}
            onWaypointClick={onWaypointClicked}
            defaultTexture={defaultTexture}
            hoverTexture={hoverTexture}
            shouldFadeOff={shouldFadeOff}
            shouldFaceCamera={!shouldShowWaypointsOnFloor}
            isHighlighted={el === closestWaypoint}
          />
        ))}
      </group>
      {
        // Render waypoint labels
        shouldDisplayWaypointLabels && (
          <WaypointLabelRender
            waypoints={waypoints}
            onLabelClick={onWaypointClicked}
          />
        )
      }
    </>
  );
}

type WalkPlaceholderProps = Pick<WalkPlaceholdersProps, "shouldFadeOff"> & {
  /** The 360 element whose placeholder we want to render */
  element: IElementImg360;

  /** The floor position for this element */
  position: Vector3;

  /** Texture used for the default state */
  defaultTexture: Texture;

  /** Texture used for the hover state */
  hoverTexture: Texture;

  /** True if the placeholders should face the camera */
  shouldFaceCamera?: boolean;

  /** Callback to signal a placeholder have been clicked */
  onWaypointClick(element: IElementImg360): void;

  /** flag to indicate if the placeholder should be highlighted */
  isHighlighted: boolean;
};

/** @returns A img360 placeholder for walk mode */
function WalkPlaceholder({
  element,
  position,
  shouldFadeOff = false,
  shouldFaceCamera,
  defaultTexture,
  hoverTexture,
  onWaypointClick,
  isHighlighted,
}: WalkPlaceholderProps): JSX.Element {
  const hasQuickNavigationFeature = useAppSelector(
    selectHasFeature(Features.WaypointNavigation),
  );

  // If WaypointNavigation feature is enabled, click on the place holder will do nothing.
  // Instead, click is handled in WalkPlaceholders to activate closest waypoint.
  // When removing the feature flag (https://faro01.atlassian.net/browse/CADBIM-1401), this
  // callback function will be simply removed.
  const placeholderClicked = useCallback(
    (ev: ThreeEvent<DomEvent>) => {
      // Prevent the click event if the user clicks a placeholder too close to the camera
      // But don't stop the propagation, so that elements behind the placeholder can still be clicked
      if (ev.distance < MIN_PLACEHOLDER_CLICK_DISTANCE) {
        return;
      }
      ev.stopPropagation();
      onWaypointClick(element);
    },
    [element, onWaypointClick],
  );

  const visibilityDistance = useAppSelector(selectVisibilityDistance);
  return (
    <PanoramaPlaceholder
      key={element.id}
      shouldFadeOff={shouldFadeOff}
      fadeDistance={visibilityDistance}
      shouldFaceCamera={shouldFaceCamera}
      position={position}
      defaultTexture={defaultTexture}
      hoverTexture={hoverTexture}
      onClicked={hasQuickNavigationFeature ? undefined : placeholderClicked}
      isHighlighted={hasQuickNavigationFeature ? isHighlighted : undefined}
    />
  );
}

function findWaypointsOnScreen(
  waypoints: WaypointPosition[],
  camera: Camera,
  domElement: HTMLElement,
): WaypointOnScreen[] {
  const TEMP = new Vector3();
  const box = new Box3(new Vector3(-1, -1, -1), new Vector3(1, 1, 1));
  const cameraPos = camera.getWorldPosition(new Vector3());

  // When waypoints are rendered facing camera, the waypoint circles are located on planes
  // that are orthogonal to camera direction vector. Need to find the vector in order to find
  // a point on the circle edge
  const cameraDirection = camera.getWorldDirection(new Vector3());
  const orthVector = new Vector3(cameraDirection.z, 0, -cameraDirection.x);
  if (Math.abs(cameraDirection.x) < Math.abs(cameraDirection.y)) {
    orthVector.set(0, cameraDirection.z, -cameraDirection.y);
  }
  orthVector.normalize().multiplyScalar(DEFAULT_CIRCLE_SIZE);

  const waypointsOnScreen = new Array<WaypointOnScreen>();
  waypoints.forEach((waypoint) => {
    TEMP.copy(waypoint.renderPosition).project(camera);
    if (box.containsPoint(TEMP)) {
      cameraVecToScreenVec(
        TEMP,
        TEMP,
        domElement.clientWidth,
        domElement.clientHeight,
      );
      const screenPos = new Vector2(TEMP.x, TEMP.y);
      const distanceToCamera =
        waypoint.renderPosition.distanceToSquared(cameraPos);

      const edgePoint = orthVector.clone().add(waypoint.renderPosition);

      // find the screen position of the edge point
      worldVecToScreenVec(
        edgePoint,
        TEMP,
        camera,
        domElement.clientWidth,
        domElement.clientHeight,
      );

      // calculate way point screen radius square
      const waypointScreenSize = screenPos.distanceToSquared(
        new Vector2(TEMP.x, TEMP.y),
      );
      waypointsOnScreen.push({
        ...waypoint,
        distanceToCamera,
        screenPos,
        waypointScreenSize,
      });
    }
  });

  return waypointsOnScreen;
}

function findClosestWaypoint(
  waypointsOnScreen: WaypointOnScreen[],
  domElement: HTMLElement,
  ev: PointerEvent,
  shouldShowWaypointsOnFloor: boolean,
): IElementImg360 | undefined {
  if (waypointsOnScreen.length === 0) return;

  // calculate distance between waypoint position on screen and the cursor position
  // and find the one that is closest to the cursor
  const screenVec = eventToScreenVec(domElement, ev, new Vector3());
  const cursorScreenPos = new Vector2(screenVec.x, screenVec.y);

  let candidateIndex = 0;
  let minDistance =
    waypointsOnScreen[0].screenPos.distanceToSquared(cursorScreenPos);
  for (let i = 1; i < waypointsOnScreen.length; i++) {
    const distanceToCursor =
      waypointsOnScreen[i].screenPos.distanceToSquared(cursorScreenPos);
    if (distanceToCursor < minDistance) {
      minDistance = distanceToCursor;
      candidateIndex = i;
    }
  }

  // if waypoints are shown on floors, simply return the one that is closest to the cursor regardless overlapping
  if (shouldShowWaypointsOnFloor) {
    return waypointsOnScreen[candidateIndex].pano;
  }

  // If waypoints are not projected on floors, there is high chance that multiple waypoints
  // will overlap on the screen. In this case, we need search the one on top out of the
  // overlapped waypoints (i.e. the one closest to camera)
  let refDistance = waypointsOnScreen[candidateIndex].distanceToCamera;
  let newCandidateIndex = candidateIndex;
  for (let i = 0; i < waypointsOnScreen.length; i++) {
    if (waypointsOnScreen[i].distanceToCamera < refDistance) {
      if (
        waypointsOnScreen[candidateIndex].screenPos.distanceToSquared(
          waypointsOnScreen[i].screenPos,
        ) <= waypointsOnScreen[i].waypointScreenSize
      ) {
        refDistance = waypointsOnScreen[i].distanceToCamera;
        newCandidateIndex = i;
      }
    }
  }

  return waypointsOnScreen[newCandidateIndex].pano;
}
