import { updateProject } from "@/components/common/project-provider/update-project";
import { isTagWritable } from "@/components/ui/tags/tag-accessibility";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import { isValid } from "@faro-lotv/ielement-types";
import { selectIElements } from "@faro-lotv/project-source";
import {
  createMutationRemoveLabel,
  Mutation,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback, useMemo } from "react";

type RemoveAllTagsCallback = () => Promise<void>;

/**
 * @returns To remove all tags from a list of iElements
 * @param elementIds The ids of the iElements whose tags should be removed
 */
export function useRemoveAllTagsFromIElements(
  elementIds: GUID[],
): RemoveAllTagsCallback {
  const iElements = useAppSelector((state) =>
    selectIElements(state, elementIds),
  );
  const validIElements = useMemo(() => iElements.filter(isValid), [iElements]);

  const { handleErrorWithToast } = useErrorHandlers();
  const { projectApiClient } = useApiClientContext();
  const dispatch = useAppDispatch();
  return useCallback(async () => {
    const mutations: Mutation[] = [];

    for (const iElement of validIElements) {
      if (!iElement.labels) continue;
      mutations.push(
        ...iElement.labels
          .filter(isTagWritable)
          .map((label) => createMutationRemoveLabel(iElement.id, label.id)),
      );
    }
    try {
      await projectApiClient.applyMutations(mutations);

      // Fetch the changed sub-tree and update the local copy of the project
      await dispatch(
        updateProject({
          projectApi: projectApiClient,
          iElementQuery: {
            ancestorIds: validIElements.map((iElement) => iElement.id),
          },
        }),
      );
    } catch (error) {
      handleErrorWithToast({ title: "Could not edit Annotation", error });
    }
  }, [dispatch, handleErrorWithToast, validIElements, projectApiClient]);
}
