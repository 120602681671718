import {
  EventType,
  GotoAnnotationProperties,
} from "@/analytics/analytics-events";
import { useAnnotationAssignees } from "@/hooks/use-annotation-assignees";
import { useGoToAnnotation } from "@/hooks/use-go-to-annotation";
import { useAppSelector } from "@/store/store-hooks";
import { Tag } from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert, GUID } from "@faro-lotv/foundation";
import {
  AnnotationStatus,
  isIElementAttachment,
  isIElementMarkup,
  isIElementMarkupDueTime,
  isIElementMarkupState,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isAnnotationStatus } from "@faro-lotv/service-wires";
import { SxProps } from "@mui/system";
import { isEqual } from "es-toolkit";
import { useMemo } from "react";
import { isTagReadable } from "../tags/tag-accessibility";
import { ResponsiveCard } from "./annotation-list-card/responsive-card";
import { ResponsiveCardVariant } from "./annotation-list-card/responsive-card-variant";

export type AnnotationListCardProps = {
  /** GUID of the annotation to render the card for */
  markupId: GUID;

  /** The card variant to use for the current list width */
  variant: ResponsiveCardVariant;

  /** The extra styling to apply to the card container */
  sx?: SxProps;
};

/** @returns a single responsive card to interact with an annotation in the list */
export function AnnotationListCard({
  markupId,
  variant,
  sx,
}: AnnotationListCardProps): JSX.Element {
  const markup = useAppSelector(selectIElement(markupId));
  assert(!!markup, "Markup does not exist");
  assert(isIElementMarkup(markup), "Input element is not a markup");

  const attachments = useAppSelector(
    selectChildrenDepthFirst(markup, isIElementAttachment),
    isEqual,
  );
  const stateElement = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupState),
  );
  const tags: Tag[] | undefined = useMemo(
    () =>
      markup.labels
        ?.filter(isTagReadable)
        .map((l) => ({ id: l.id, label: l.name, labelType: l.labelType })),
    [markup.labels],
  );
  const state = isAnnotationStatus(stateElement?.value)
    ? stateElement.value
    : AnnotationStatus.Unclassified;
  const mainAssignee = useAnnotationAssignees(markup).at(0);

  const goToAnnotation = useGoToAnnotation(markup);

  const dueDate = useAppSelector(
    selectChildDepthFirst(markup, isIElementMarkupDueTime),
  );

  return (
    <ResponsiveCard
      attachments={attachments}
      name={markup.name}
      tags={tags}
      state={state}
      mainAssignee={mainAssignee}
      dueDate={dueDate?.value}
      markup={markup}
      variant={variant}
      onGoTo={() => {
        Analytics.track<GotoAnnotationProperties>(EventType.gotoAnnotation, {
          via: "annotation list",
        });

        goToAnnotation();
      }}
      sx={sx}
    />
  );
}
