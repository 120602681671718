import { assert } from "@faro-lotv/foundation";
import {
  Coordinates,
  MetaDataFromSvfDescription,
} from "@faro-lotv/ielement-types";
import { Matrix4, Vector3 } from "three";

// So many CS (coordinates systems) is requiring some explanation:
// - the model's CS is the X/Y/Z coordinate system of the data inside the CAD file;
//   rarely used for Revit, Navisworks, but critical for Steps
// - the exported mesh CS is the CS used for the glTF mesh exported by the CadImporter worker;
//   in addition to the typical conversion of Z-up from the CAD to Y-up for glTF,
//   it could include an additional rotation implemented by CadImporter
//   back then when the extraction of north vector was not yet implemented
// - Sphere has 2 CS:
//   - the display CS is the one visible to Sphere XG Viewer user: Z up, Y north, X east
//   - the internal CS is the one used by Sphere internally: Y up, Z south, X east
// - some Revit, Navisworks, and Autocad models defined additional CS:
//   - the "view" CS is defined by a "up" vector and a "front" vector (from model to viewer)
//   - the "north" CS is defined by a "up" vector and a "north" vector;
//     this is typically the project's north, which is the top of the floor map, not the real geographical north
//   - the "true north" CS is defined by a "up" vector and a "true north" vector;
//     the true north is typically the real geographical north (measured by a compass)
//   - the Ref Point CS is a complete transformation (including a translation and rotation) to the Survey point and CS
//
// MetaDataFromSvfDescription contains values generated by the CadImporter and describing key vectors/axis for these CS.
//
// getAllModelTransformations is converting these information into Matrix4 encoding the transformation from the exported mesh to
// for the CS mentioned before.

/**
 * Convert a Coordinates to a Vector3
 *
 * @param coord coordinates as a Coordinates
 * @returns the coordinates as a Vector3
 */
export function coordinatesToVector3(coord: Coordinates): Vector3 {
  return new Vector3(coord.X, coord.Y, coord.Z);
}

/**
 * Get the transformation Matrix4 from A to B by combining the transformation from A to C, and the one from B to C.
 *
 * @param fromAtoC transformation from CS A to CS C
 * @param fromBtoC transformation from CS B to CS C
 * @returns The transformation matrix from CS A to CS B
 */
export function combineIndirectConversion(
  fromAtoC: Matrix4,
  fromBtoC: Matrix4,
): Matrix4 {
  const fromCtoB = fromBtoC.clone().invert();
  return combineDirectConversion(fromAtoC, fromCtoB);
}

/**
 * Get the transformation Matrix4 from A to B by combining the transformation from A to C, and the one from C to B.
 *
 * @param fromAtoC transformation from CS A to CS C
 * @param fromCtoB transformation from CS C to CS B
 * @returns The transformation matrix from CS A to CS B
 */
export function combineDirectConversion(
  fromAtoC: Matrix4,
  fromCtoB: Matrix4,
): Matrix4 {
  return fromCtoB.clone().multiply(fromAtoC);
}

/**
 * Given 2 vectors that might not be perfectly normal nor normalized, return one that is orthogonal to the first one and normalized.
 *
 * @param stableVector reference vector
 * @param vectorToFix vector to be fixed
 * @returns a new normalized vector normal to stableVector and as close as possible to vectorToFix
 */
function fixVectorToBeNormal(
  stableVector: Vector3,
  vectorToFix: Vector3,
): Vector3 {
  const normalVector = new Vector3().crossVectors(stableVector, vectorToFix);
  return new Vector3().crossVectors(normalVector, stableVector).normalize();
}

/**
 * @returns The transformation matrix from the exported mesh's coordinate system to the model's coordinate system.
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 */
export function getTransformationFromExportedMeshToModel(
  metadata: MetaDataFromSvfDescription,
): Matrix4 {
  // Compute the transformation for the model's system CS (from mesh to model)
  const modelYInMeshCS: Vector3 = coordinatesToVector3(
    metadata.ModelYInMeshCS,
  ).normalize();
  const modelZInMeshCS: Vector3 = fixVectorToBeNormal(
    modelYInMeshCS,
    coordinatesToVector3(metadata.ModelZInMeshCS),
  );
  const modelXInMeshCS: Vector3 = new Vector3().crossVectors(
    modelYInMeshCS,
    modelZInMeshCS,
  );

  return new Matrix4()
    .makeBasis(modelXInMeshCS, modelYInMeshCS, modelZInMeshCS)
    .invert();
}

/**
 * @returns The transformation from exported mesh to Sphere internal CS (the one with Y up) given the up and north vectors in model CS;
 *  undefined if vectors are collinear
 * @param meshToModel transformation from exported mesh to model's system CS
 * @param upVectorInModelCS coordinate of up vector in model CS; not normalized vector is ok
 * @param northVectorInModelCS coordinate of north vector in model CS (north vector in Sphere is opposite of front vector);
 *  not perfectly normal to upVectorInModelCS, or not normalized vector is ok
 * @param userToInternal transformation from Sphere display CS to Sphere internal CS (Y up instead of Z up)
 */
function getTransformationFromExportedMeshToSphereInternalCS(
  meshToModel: Matrix4,
  upVectorInModelCS: Vector3,
  northVectorInModelCS: Vector3,
  userToInternal: Matrix4,
): Matrix4 | undefined {
  // up vector is Z axis in Sphere display
  const zAxis = upVectorInModelCS.clone().normalize();
  // north vector is Y axis in Sphere display
  const yAxis = fixVectorToBeNormal(zAxis, northVectorInModelCS);
  const xAxis = new Vector3().crossVectors(yAxis, zAxis);

  const epsilon = 1e-6;
  if (
    zAxis.lengthSq() < epsilon ||
    xAxis.lengthSq() < epsilon ||
    yAxis.lengthSq() < epsilon
  ) {
    return;
  }

  const viewToModel = new Matrix4().makeBasis(xAxis, yAxis, zAxis);

  const meshToView = combineIndirectConversion(meshToModel, viewToModel);
  return combineDirectConversion(meshToView, userToInternal);
}

/**
 * @returns The optional transformation matrix to be applied on exported mesh to apply the model view's coordinate system
 * @param meshToModel transformation from exported mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 * @param userToInternal transformation from Sphere display CS to Sphere internal CS (Y up instead of Z up)
 */
function getMeshTransformationForView(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
  userToInternal: Matrix4,
): Matrix4 | undefined {
  if (!metadata.UpVectorInModelCS || !metadata.FrontVectorInModelCS) {
    return undefined;
  }

  const upVector = coordinatesToVector3(metadata.UpVectorInModelCS);
  // for this CS, we want front=south=opposite of north
  const northVector = coordinatesToVector3(
    metadata.FrontVectorInModelCS,
  ).multiplyScalar(-1);

  return getTransformationFromExportedMeshToSphereInternalCS(
    meshToModel,
    upVector,
    northVector,
    userToInternal,
  );
}

/**
 * @returns The optional transformation matrix to be applied on exported mesh to apply the model north's coordinate system
 * undefined if either up or north vectors are missing
 * @param meshToModel transformation from mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 * @param userToInternal transformation from Sphere display CS to Sphere internal CS (Y up instead of Z up)
 */
function getMeshTransformationForNorth(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
  userToInternal: Matrix4,
): Matrix4 | undefined {
  if (!metadata.NorthVectorInModelCS || !metadata.UpVectorInModelCS) {
    return undefined;
  }

  const upVector = coordinatesToVector3(metadata.UpVectorInModelCS);
  const northVector = coordinatesToVector3(metadata.NorthVectorInModelCS);

  return getTransformationFromExportedMeshToSphereInternalCS(
    meshToModel,
    upVector,
    northVector,
    userToInternal,
  );
}

/**
 * @returns The optional transformation matrix to be applied on exported mesh to apply the model true north's coordinate system
 * undefined if either up or true north vectors are missing
 * @param meshToModel transformation from mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 * @param userToInternal transformation from Sphere display CS to Sphere internal CS (Y up instead of Z up)
 */
function getMeshTransformationForTrueNorth(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
  userToInternal: Matrix4,
): Matrix4 | undefined {
  if (!metadata.TrueNorthVectorInModelCS || !metadata.UpVectorInModelCS) {
    return undefined;
  }

  const upVector = coordinatesToVector3(metadata.UpVectorInModelCS);
  const northVector = coordinatesToVector3(metadata.TrueNorthVectorInModelCS);

  return getTransformationFromExportedMeshToSphereInternalCS(
    meshToModel,
    upVector,
    northVector,
    userToInternal,
  );
}

/**
 * @returns The scale factor to convert the model's units to meters
 * @param units the units of the model
 * DESIGN NOTES
 * The conversion to meter should have been done by the CadImporter worker!!! Will be fixed later :-\
 */
function getModelUnitsToMeterScale(units: string | undefined): number {
  // number of meters in one inch
  const inchInMeter = 0.0254;
  // number of meters in one foot
  const feetInMeter = inchInMeter * 12;
  switch (units?.toLocaleLowerCase()) {
    case "meters":
    case "meter":
    case "m":
      return 1;
    case "centimeters":
    case "centimeter":
    case "cm":
      return 0.01;
    case "millimeters":
    case "millimeter":
    case "mm":
      return 0.001;
    case "feet":
    case "foot":
    case "ft":
      return feetInMeter;
    case "inches":
    case "inch":
    case "in":
      return inchInMeter;
    case undefined:
      return 1;
    default:
      assert(false, `Unknown units: ${units}`);
  }
}

/**
 * @returns the optional Matrix4 transformation from model's CS to Ref Point in meter; undefined if Ref Point is not defined
 * @param metadata MetaDataFromSvfDescription for the model
 */
function getRefPointTransformInMeter(
  metadata: MetaDataFromSvfDescription,
): Matrix4 | undefined {
  if (!metadata.RefPointTransform) return undefined;

  const modelToRef = new Matrix4().fromArray(
    metadata.RefPointTransform.JsonData,
  );
  // convert modelToRef from model's units to meter
  const translationInModelUnits = new Vector3().setFromMatrixPosition(
    modelToRef,
  );
  const scale = getModelUnitsToMeterScale(metadata.DistanceUnits);
  const translationInMeter = translationInModelUnits.multiplyScalar(scale);
  modelToRef.setPosition(translationInMeter);

  return modelToRef;
}

/**
 * @returns The optional transformation matrix to be applied on exported mesh to transform to the model Ref Point CS
 * undefined if Ref Point transformation is missing
 * @param meshToModel transformation from mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 * @param userToDisplay transformation from Sphere display CS to Sphere internal CS (Y up instead of Z up)
 */
function getMeshTransformationForRefPoint(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
  userToDisplay: Matrix4,
): Matrix4 | undefined {
  const modelToRef = getRefPointTransformInMeter(metadata);
  if (!modelToRef) return undefined;

  const meshToRef = combineDirectConversion(meshToModel, modelToRef);
  return combineDirectConversion(meshToRef, userToDisplay);
}

/**
 * @returns The optional coordinate of ref point in the imported mesh coordinate system
 * undefined if Ref Point transformation is missing
 * @param meshToModel transformation from mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the transformation from
 */
function getModelRefPointInImportedMeshCS(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
): Vector3 | undefined {
  const modelToRef = getRefPointTransformInMeter(metadata);
  if (!modelToRef) return undefined;

  // Ref Point is [0,0,0] in Ref Point CS
  const refPointInModelCS = new Vector3().applyMatrix4(
    modelToRef.clone().invert(),
  );
  return refPointInModelCS.applyMatrix4(meshToModel.clone().invert());
}

/**
 * @returns The optional coordinates of project base point in the imported mesh coordinate system
 * undefined if Project Base Point is missing
 * @param meshToModel transformation from mesh to model's system CS
 * @param metadata MetaDataFromSvfDescription to extract the coordinates from
 */
function getProjectBasePointInImportedMeshCS(
  meshToModel: Matrix4,
  metadata: MetaDataFromSvfDescription,
): Vector3 | undefined {
  if (!metadata.BasePointInModelCS) return undefined;

  return coordinatesToVector3(metadata.BasePointInModelCS).applyMatrix4(
    meshToModel.clone().invert(),
  );
}

// Define the rotation from exported mesh to new mesh when we want any specific CS to match the one displayed in Sphere
export type ModelTransformations = {
  // Rotation from exported mesh to new mesh in model's system CS
  toModelCS: Matrix4;

  // Optional rotation from exported mesh to new mesh in model's view CS (defined by up = Z and front = -Y)
  toModelView: Matrix4 | undefined;

  // Optional rotation from exported mesh to new mesh in model's north CS (defined by up = Z and north = Y)
  toModelNorth: Matrix4 | undefined;

  // Optional rotation from exported mesh to new mesh in model's true north CS (defined by up = Z and true north = Y)
  toModelTrueNorth: Matrix4 | undefined;

  // Optional transformation from exported mesh to new mesh in model's ref point and coordinates (include both a translation and a rotation)
  toRefPoint: Matrix4 | undefined;

  // Optional location of model's ref point in exported mesh coordinates system
  refPointInMeshCs: Vector3 | undefined;

  // Optional location of model's project base point in exported mesh coordinates system
  projectBasePointInMeshCs: Vector3 | undefined;
};

/**
 * @returns All the transformations from the exported mesh's coordinate system to
 * the new visible mesh for various optional model's coordinate systems
 * @param metadata MetaDataFromSvfDescription to extract the transformations from
 */
export function getAllModelTransformations(
  metadata: MetaDataFromSvfDescription,
): ModelTransformations {
  // the transformation to convert from sphere user CS (Z up) to Sphere internal CS (Y up)
  const SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS = Object.freeze(
    new Matrix4().makeBasis(
      new Vector3(1, 0, 0),
      new Vector3(0, 0, -1),
      new Vector3(0, 1, 0),
    ),
  );

  const meshToModel = getTransformationFromExportedMeshToModel(metadata);

  return {
    toModelCS: combineDirectConversion(
      meshToModel,
      SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS,
    ),
    toModelView: getMeshTransformationForView(
      meshToModel,
      metadata,
      SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS,
    ),
    toModelNorth: getMeshTransformationForNorth(
      meshToModel,
      metadata,
      SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS,
    ),
    toModelTrueNorth: getMeshTransformationForTrueNorth(
      meshToModel,
      metadata,
      SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS,
    ),
    toRefPoint: getMeshTransformationForRefPoint(
      meshToModel,
      metadata,
      SPHERE_USER_CS_TO_SPHERE_INTERNAL_CS,
    ),
    refPointInMeshCs: getModelRefPointInImportedMeshCS(meshToModel, metadata),
    projectBasePointInMeshCs: getProjectBasePointInImportedMeshCS(
      meshToModel,
      metadata,
    ),
  };
}
